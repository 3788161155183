const settings = {
    dev: "http://localhost:8030",
    demo: "https://sandr.tk/api",
    prod: "https://hdis.hdonefocus.com",
};

const getBaseURL = () => {
    // if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    //     return settings.dev;
    // }
    //for production
    return settings.prod;
};

export const baseURL = getBaseURL();
export default baseURL;
