<template>
    <div :class="$style.container">
        <AllDoneIcon color="#FFC003" :size="63" />
        <div :class="$style.allDone">All done!</div>
        <div :class="$style.description">
            Thank you for completing this month's module. Using the tips that you have just seen will result in a safer store for you, your fellow
            associates, and our customers.
        </div>
        <n-button ghost :class="$style.exitButton" @click="send">Exit</n-button>
    </div>
</template>

<script>
import AllDoneIcon from "../icons/AllDoneIcon.vue";
import { useMessage } from "naive-ui";
import { useStore } from "vuex";
import resultsAPI from "../api/results";
import { useRouter } from "vue-router";
import { computed } from "@vue/runtime-core";

export default {
    name: "AllDone",
    components: { AllDoneIcon },
    setup() {
        const router = useRouter();
        const store = useStore();
        const message = useMessage();
        const user = computed(() => store.state.user);

        const send = () => {
            const data = { ...user.value };
            data.completed = Date.now();
            console.log(data);
            store.dispatch("setLoading", true);
            resultsAPI
                .addResult(data)
                .then((response) => {
                    store.dispatch("setLoading", false);
                    if (response.status === 200) {
                        message.success("Data sent successfully!");
                        router.push("/");
                    } else {
                        message.error("Error! Please try again later!");
                    }
                })
                .catch((err) => {
                    console.log(err);
                    message.error("Error! Please try again later!");
                    store.dispatch("setLoading", false);
                });
        };

        return {
            send,
        };
    },
};
</script>

<style module>
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
    background: conic-gradient(from 201.76deg at 43.16% 52.18%, #494747 0deg, #63696d 360deg);
}
.allDone {
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    text-align: center;
    letter-spacing: 0.005em;
    color: #ffffff;
    margin-top: 45px;
}
.description {
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    letter-spacing: 0.005em;
    color: #ffffff;
    width: 420px;
    margin-top: 18px;
}
.exitButton {
    margin-top: 50px;
    width: 190px;
    height: 50px;
    border-radius: 8px;
    color: white;
    margin-bottom: 20px;
    --n-border: 2px solid white !important;
    --n-ripple-color: #fff;
    --n-text-color: #fff;
    --n-text-color-hover: #fff;
    --n-text-color-pressed: #fff;
    --n-text-color-focus: #fff;
    --n-text-color-disabled: #fff;
    --n-border: 1px solid rgb(224, 224, 230);
    --n-border-hover: 1px solid #fff;
    --n-border-pressed: 1px solid #fff;
    --n-border-focus: 1px solid #fff;
    --n-ripple-color: #fff;
    --n-text-color: #fff;
    --n-text-color-hover: #fff;
    --n-text-color-pressed: #fff;
    --n-text-color-focus: #fff;
    --n-text-color-disabled: #fff;
    --n-border: 1px solid #fff;
    --n-border-hover: 1px solid #fff;
    --n-border-pressed: 1px solid #fff;
    --n-border-focus: 1px solid #fff;
}
button {
    font-weight: 700;
}
</style>
