<template>
    <n-icon :size="size">
        <svg width="63" height="63" viewBox="0 0 63 63" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M31.5 0C25.2699 0 19.1797 1.84744 13.9995 5.30871C8.8194 8.76997 4.78197 13.6896 2.39781 19.4455C0.0136484 25.2013 -0.610156 31.5349 0.605278 37.6453C1.82071 43.7557 4.8208 49.3685 9.22615 53.7739C13.6315 58.1792 19.2443 61.1793 25.3547 62.3947C31.4651 63.6102 37.7987 62.9864 43.5545 60.6022C49.3104 58.218 54.23 54.1806 57.6913 49.0005C61.1526 43.8203 63 37.7301 63 31.5C62.9908 23.1485 59.6691 15.1417 53.7637 9.23626C47.8583 3.33086 39.8515 0.00917153 31.5 0ZM31.5 56.7C26.5159 56.7 21.6438 55.222 17.4996 52.453C13.3555 49.684 10.1256 45.7483 8.21825 41.1436C6.31092 36.5389 5.81188 31.472 6.78422 26.5837C7.75657 21.6954 10.1566 17.2052 13.6809 13.6809C17.2052 10.1566 21.6954 7.75656 26.5837 6.78421C31.4721 5.81186 36.5389 6.31091 41.1436 8.21824C45.7483 10.1256 49.684 13.3555 52.453 17.4996C55.222 21.6437 56.7 26.5159 56.7 31.5C56.6925 38.1811 54.0351 44.5865 49.3108 49.3108C44.5865 54.0351 38.1812 56.6925 31.5 56.7Z"
                fill="#F96302"
            />
            <path
                d="M43.2162 26.5066C44.2613 25.4756 44.2613 23.8042 43.2162 22.7732C42.1712 21.7423 40.4805 21.7423 39.4355 22.7732L27.4228 34.6242L22.5685 29.8282C21.5235 28.7972 19.8257 28.7972 18.7842 29.8282C17.7392 30.8556 17.7392 32.5305 18.7807 33.5615L25.5289 40.2259C26.0318 40.722 26.713 41 27.4228 41L27.844 40.9671C28.3978 40.8801 28.9137 40.6228 29.3132 40.2259L43.2162 26.5066Z"
                fill="white"
            />
        </svg>
    </n-icon>
</template>

<script>
export default {
    name: "AllDoneIcon",
    props: {
        color: String,
        size: Number,
    },
};
</script>

<style scoped></style>
